// Miscellaneous variables

// Border
$border-width: 1px !default;
$border-style: solid !default;
$border: $border-width $border-style var(--color-border-default) !default;

// Border Radius
$border-radius-1:  4px !default;
$border-radius-2:  6px !default;
$border-radius-3:  8px !default;
$border-radius: $border-radius-2 !default;

// Tooltips
$tooltip-max-width: 250px !default;
$tooltip-delay: 0.4s !default;
$tooltip-duration: 0.1s !default;
