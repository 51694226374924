@import '@primer/css/support/index.scss';

/* Native color mode browser resets*/
@import '@primer/css/color-modes/native.scss';

/* Primer CSS core and product styles*/
@import '@primer/css/core/index.scss';
@import '@primer/css/product/index.scss';

/* Primer View Components*/
@import '@primer/view-components/app/assets/styles/primer_view_components.css';
